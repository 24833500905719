import { Tab, Container, Row, Col, Form, Tabs, Button, Alert } from "react-bootstrap";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import styled from "styled-components";
import clsx from "clsx";

import {
  getSubscriptionStatus,
  currencyFormatter,
  datePickerFormatter,
  combineFromCategories,
  asyncOptionsList,
  phoneCleaner,
  phoneNumRegexp,
  checkIsIncLLCIncorporation,
  checkIsTrademarkMonitoring,
  checkIsLLCIncorporation,
  checkIsStateIncorporationFee,
  checkIsIncEin,
} from "_helpers";

import ConfirmationModal from "components/ConfirmationModal";

import OrderProductSelect from "components/OrderDetails/OrderProductSelect";
import RelatedOrders from "components/OrderDetails/RelatedOrders";
// EIN
import FormPartnership from "components/OrderDetails/ProductDetails/EINForms/Edit/FormPartnership";
import FormCCorp from "components/OrderDetails/ProductDetails/EINForms/Edit/FormCCorp";
import FormSoleProp from "components/OrderDetails/ProductDetails/EINForms/Edit/FormSoleProp";
import FormEstate from "components/OrderDetails/ProductDetails/EINForms/Edit/FormEstate";
import FormTrust from "components/OrderDetails/ProductDetails/EINForms/Edit/FormTrust";
import FormLLC from "components/OrderDetails/ProductDetails/EINForms/Edit/FormLLC";
import FormPSC from "components/OrderDetails/ProductDetails/EINForms/Edit/FormPSC";
import FormSCorp from "components/OrderDetails/ProductDetails/EINForms/Edit/FormSCorp";
import FormChurch from "components/OrderDetails/ProductDetails/EINForms/Edit/FormChurch";
import FormProfit from "components/OrderDetails/ProductDetails/EINForms/Edit/FormProfit";
// Incorporation
import FormLLCInc from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormLLCInc";
import FormResidentAgent from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormResidentAgent";
import StateIncFee from "components/OrderDetails/ProductDetails/Incorporation/StateIncFee";
import FormAnnualComplianceReport from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormAnnualComplianceReport";
import FormRegisteredAgent from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormRegisteredAgent";
import FormBusinessLicenses from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormBusinessLicenses";
import FormIncRushProcessing from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormIncRushProcessing";
import FormIncorpifyAmendment from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormIncorpifyAmendment";
import FormBoi from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormBoi";
import FormCreditCardProcessing from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormCreditCardProcessing";
import FormAnnualReport from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormAnnualReport";
import FormDBA from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormDBA";
// Vital
import VitalRecord from "components/OrderDetails/ProductDetails/VitalRecord/EditVitalRecord";
// Trademark
import FormTrademarkRegister from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkRegister";
import FormTrademarkSearch from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkSearch";
import FormTrademarkMonitoring from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkMonitoring";
import FormTrademarkStatementOfUse from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkStatementOfUse";
import FormTrademarkOfficeActionResponse from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkOfficeActionResponse";
import FormTrademarkRenewal from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkRenewal";
import FormTrademarkRushProcessing from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkRushProcessing";
import FormTrademarkExtension from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkExtension";
import FormTrademarkRevivalFromAbandonment from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkRevivalFromAbandonment";
import FormTrademarkUSPTOFilingFee from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkUSPTOFilingFee";
import FormTrademarkSummary from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkSummary";
import FormTrademarkKeepAlive from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkKeepAlive";
import FormTrademarkCertifiedCopy from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkCertifiedCopy";
import FormTrademarkAmendment from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkAmendment";
import FormTrademarkPrivacyPackage from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkPrivacyPackage";
import FormTrademarkPrivateContactInfo from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkPrivateContactInfo";
import FormTrademarkAssessment from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkAssessment";
import FormTrademarkLogoDesignService from "components/OrderDetails/ProductDetails/TrademarkForms/Edit/FormTrademarkLogoDesignService";

// Amendments
import FormIncorpifyRaAmendment from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormIncorpifyRaAmendment";
import FormIncorpifyManagerAmendment from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormIncorpifyManagerAmendment";
import FormIncorpifyBusinessAddressAmendment from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormIncorpifyBusinessAddressAmendment";
import FormIncorpifyOtherAmendment from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormIncorpifyOtherAmendment";
import FormIncorpifyBusinessNameAmendment from "components/OrderDetails/ProductDetails/Incorporation/Edit/FormIncorpifyBusinessNameAmendment";

import FormDefaultEdit from "components/OrderDetails/ProductDetails/FormDefaultEdit";

import Emails from "components/OrderDetails/Emails";
import FilingFees from "components/OrderDetails/FilingFees";
import PaymentRefunds from "components/OrderDetails/PaymentRefunds";
import OrderActions from "components/OrderDetails/Actions";
import Notes from "components/OrderDetails/Notes";
import Zendesk from "components/OrderDetails/Zendesk";
import Timeline from "components/OrderDetails/Timeline";
import PasswordReset from "components/OrderDetails/PasswordReset";
import Monitoring from "components/OrderDetails/Monitoring";
import MailUpload from "components/OrderDetails/MailUpload";
import Billing from "components/OrderDetails/Billing";
import AmendmentsLLC from "components/OrderDetails/AmendmentsLLC";
import CustomerbActions from "components/OrderDetails/CustomerActions";

import { state_array, TRADEMARK_REGISTER_CODES, TAB_CODES } from "variables/Variables";
import LogoDesignRevisions from "components/OrderDetails/LogoDesignRevisions";

const initError = Object.values(TAB_CODES)?.reduce((newObj, item) => ({ ...newObj, [item]: [] }), {});

const StyledForm = styled(Form)`
  .form-control:valid,
  .form-control:invalid {
    background-position: right calc(0.9em + 0.1875rem) center;
    background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
  }
`;

function EditOrderDetail({
  activeTab,
  orderDetails,
  selectedProduct,
  availableProcessingStatus,
  onSelectedProduct,
  uncollapsedProducts,
  onViewChange,
  onTabChange,
  onChange,
  onUpdateOrder,
  onDeleteOrder,
  onUncollapseProduct,
}) {
  if (!orderDetails) return null;
  const productDetails = orderDetails?.products[selectedProduct];

  const [validated, setValidated] = useState(false);
  const [isAssigneeRoleMatchesType, setIsAssigneeRoleMatchesType] = useState(true);
  const [isDirty, setDirty] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [errors, setErrors] = useState(initError);
  const [initOrderDetails, setInitOrderDetails] = useState({});
  const [accountManager, setAccountManager] = useState(null);
  const {
    // processingErrorsOpt,
    // orderStatusOpt,
    // processingStatusOpt,
    productsProcessingStatuses,
    productsProcessingStatusesOpt,
    ...variants
  } = useSelector(({ variants }) => variants);
  const { subscriptionDetails } = useSelector(({ customers }) => customers);
  // const processingStatusOpt = combineFromCategories(variants.allProcessingStatuses);
  const orderStatusOpt = combineFromCategories(variants.allOrderStatuses);
  const processingErrorsOpt = combineFromCategories(variants.allProcessingErrors);
  const processingStatusesOpt = combineFromCategories(variants.allProcessingStatuses);

  const monitoringStatus = getSubscriptionStatus(orderDetails, selectedProduct, subscriptionDetails);

  const dataform = useRef(null);
  const uploadPDFRef = useRef(null);

  const hasMembers = () => {
    const productLLCInc = orderDetails?.products?.find((product) => checkIsIncLLCIncorporation(product?.code?.code));
    const productEINLLC = orderDetails?.products?.find((product) => checkIsIncEin(product?.code?.code));

    if (!productEINLLC || !productLLCInc) {
      return true;
    }

    return productEINLLC?.numberOfMembers > 0;
  };

  const isTrademark = orderDetails?.products?.some(
    ({ code }) =>
      code?.category === "TRADEMARKS" ||
      code?.code === "INC_RegisterTrademark" ||
      code?.code === "INC_States_RegisterTrademark"
  );

  const trademarkMonitoringProductIdx = orderDetails?.products?.findIndex(({ code }) =>
    checkIsTrademarkMonitoring(code?.code)
  );

  // const isTrademarkMonitoring = trademarkMonitoringProductIdx !== -1;
  const isTrademarkMonitoring = (code) => checkIsTrademarkMonitoring(code);

  const hasTrademarkRegistration = orderDetails?.products?.some(({ code }) =>
    TRADEMARK_REGISTER_CODES.includes(code?.code)
  );

  const isPrivacyProduct = (code) =>
    ["TRADEMARK_Private_Contact_Information", "TRADEMARK_Privacy_Package"].includes(code);

  const subscriptionStatus = productDetails?.code?.subscription && getSubscriptionStatus(orderDetails, selectedProduct);

  const isTrademarkRegistration = (code) => TRADEMARK_REGISTER_CODES.includes(code);

  const isLlcIncorporation = (code) => checkIsLLCIncorporation(code);

  const isLegalTeamAssesment = (code) => ["TRADEMARK_Legal_Team_Trademark_Assessment"].includes(code);

  const isLogoDesingService = (code) => ["TRADEMARK_Logo_Design_Service"].includes(code);

  const statusIndicatorClasses = (status) =>
    clsx("tab-status-indicator", {
      ["bg-success"]: status === "Active",
      ["bg-warning"]: status === "Declined",
      ["bg-danger"]: status === "Cancelled",
      ["bg-default"]: !["Active", "Declined", "Cancelled"].includes(status),
    });

  const productsErrors = orderDetails.products
    .filter(({ processingResult: { status } }) => status === "Error")
    .map(({ processingResult: { processingError } }) => processingErrorsOpt[processingError])
    .join(", ");

  const addError = (tab, field) => {
    // if (!errors[tab]?.includes(field)) {
    setErrors((prevErrors) => ({ ...prevErrors, [tab]: [...prevErrors[tab], field] }));
    // }
  };

  const handleValidateActions = ({ isAssigneeRoleMatchesType }) => {
    setIsAssigneeRoleMatchesType(isAssigneeRoleMatchesType);
  };

  const hasCreditCardProcessingTypes = () => {
    const cardProcessingProduct = orderDetails?.products?.find((product) =>
      ["INC_Credit_Card_Processing", "INC_States_Credit_Card_Processing"].includes(product?.code?.code)
    );

    if (!cardProcessingProduct) return true;

    return cardProcessingProduct?.online || cardProcessingProduct?.inPerson;
  };

  const handleChangeOrder = (newOrder) => {
    if (!isDirty) setDirty(true);

    onChange(newOrder);
  };

  const handleChangeCustomer = ({ target: { name, value } }) => {
    let newData = value;

    if (name === "phone") {
      newData = phoneCleaner(newData);
    }

    handleChangeOrder({ ...orderDetails, customer: { ...orderDetails.customer, [name]: newData } });
  };

  const handleChangeOrderDate = ({ target: { name, value } }) => {
    if (!value || value === "Invalid date") return;

    const [currentProductField, currentProductId] = name.split("-");

    const currentTime = moment(`${value}T${moment().format("hh:mm:ss")}`)
      .tz("UTC")
      .format("YYYY-MM-DDTHH:mm:ss");

    handleChangeOrder({
      ...orderDetails,
      products: orderDetails.products.map((product) => {
        if (product.id === currentProductId) {
          return { ...product, [currentProductField]: currentTime };
        }
        return product;
      }),
    });
  };

  const handleChangeAccountManager = (props) => {
    if (props) {
      const { label, value } = props;
      setAccountManager({ label, value });
      handleChangeOrder({ ...orderDetails, order: { ...orderDetails.order, accountManagerId: value } });
    } else {
      setAccountManager(null);
      handleChangeOrder({ ...orderDetails, order: { ...orderDetails.order, accountManagerId: null } });
    }
  };

  const handleChangeCustomerContactRequired = ({ target: { value } }) => {
    handleChangeOrder({ ...orderDetails, order: { ...orderDetails.order, customerContactRequired: value } });
  };

  const handleChangeBillingAddress = ({ target: { name, value } }) =>
    handleChangeCustomer({
      target: { name: "billingAddress", value: { ...orderDetails.customer.billingAddress, [name]: value } },
    });

  const handleShowConfirmation = (cb) => {
    uploadPDFRef.current = cb;
    setConfirmationOpen(true);
  };

  const handleUpdateOrder = (e) => {
    if (e) e.preventDefault();
    setErrors(initError);

    if (dataform.current.checkValidity() === false) {
      if (e) e.stopPropagation();
      setValidated(true);
    } else {
      if (
        moment(productDetails.startDate).isAfter(productDetails.firstDateWages, "date") ||
        moment(productDetails.firstDateWages).isAfter(moment().add(1, "year").format(), "date")
      ) {
        addError(TAB_CODES.einInfo, "Wages");
        onChange({
          ...orderDetails,
          products: orderDetails.products.map((product, idx) =>
            idx === selectedProduct ? { ...product, firstDateWages: null } : product
          ),
        });
        setValidated(true);
      } else if (!hasMembers()) {
        addError(TAB_CODES.llcIncInfo, "Members");
      } else if (!hasCreditCardProcessingTypes()) {
        addError(TAB_CODES.defaultInfo, "CreditCardProcessing");
      } else {
        if (isAssigneeRoleMatchesType) {
          onUpdateOrder(uploadPDFRef.current);
        }
      }
    }
  };

  useEffect(() => {
    if (!isAssigneeRoleMatchesType) {
      addError(TAB_CODES.actions, "Assignee Type and Assignee User cannot be empty");
    } else {
      if (errors[TAB_CODES.actions].length) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          newErrors[TAB_CODES.actions] = [];
          return newErrors;
        });
      }
    }
  }, [isAssigneeRoleMatchesType, errors]);

  useEffect(() => {
    if (!isDirty) setInitOrderDetails({ ...orderDetails });
  }, [isDirty, orderDetails]);

  let customerName = "";

  if (orderDetails) {
    switch (productDetails.code.code) {
      case "EIN_Partnership":
      case "EIN_CCorp":
      case "EIN_LLC":
      case "INC_EIN":
      case "INC_States_EIN":
      case "EIN_SCorp":
      case "EIN_Trust":
      case "EIN_PSC":
      case "EIN_NonProfit":
      case "EIN_Church":
        customerName = productDetails.legalName;
        break;

      case "EIN_SoleProp":
        customerName = `${productDetails.owner.firstName} ${productDetails.owner.lastName}`;
        break;

      case "EIN_Estate":
        customerName = `${productDetails.deceased.firstName} ${productDetails.deceased.lastName}`;
        break;

      case "INC_RegisterTrademark":
      case "INC_States_RegisterTrademark":
      case "TRADEMARK_RegisterTrademark":
      case "TRADEMARK_RegisterTrademarkV2":
      case "TRADEMARK_RegisterTrademark_Basic":
      case "TRADEMARK_RegisterTrademark_Standard":
      case "TRADEMARK_RegisterTrademark_Platinum":
        customerName = `${productDetails?.individual?.firstName} ${productDetails?.individual?.lastName}`;
        break;

      default:
        break;
    }
  }

  const infoTabRenderer = () => {
    let tabInfo = null;
    const tabProps = {
      validated,
      orderDetails,
      selectedProduct,
      isDirty,
      onError: addError,
      onChange: handleChangeOrder,
      onShowConfirmation: handleShowConfirmation,
    };

    switch (productDetails.code.code) {
      case "VITAL_NewBirthCertificate": {
        tabInfo = {
          eventKey: "vital_record",
          title: TAB_CODES.vitalRecord,
          c: <VitalRecord {...tabProps} />,
        };
        break;
      }
      case "INC_RegisteredAgent":
      case "INC_States_RegisteredAgent": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormRegisteredAgent {...tabProps} />,
        };
        break;
      }
      case "EIN_ResidentAgent":
      case "INC_ResidentAgent":
      case "INC_States_ResidentAgent": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormResidentAgent {...tabProps} />,
        };
        break;
      }
      case "INC_BOI":
      case "INC_States_BOI":
      case "EIN_BOI": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.boiInfo,
          c: <FormBoi {...tabProps} />,
        };
        break;
      }

      case "INC_AnnualReport":
      case "INC_States_AnnualReport": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormAnnualReport {...tabProps} />,
        };
        break;
      }
      case "EIN_AnnualComplianceReport":
      case "EIN_AnnualComplianceReport_Pre":
      case "INC_AnnualComplianceReport":
      case "INC_States_AnnualComplianceReport": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormAnnualComplianceReport {...tabProps} />,
        };
        break;
      }
      case "EIN_LLCIncorporation":
      case "INC_LLCIncorporation":
      case "INC_States_LLCIncorporation":
      case "INC_LLCIncorporationV2":
      case "INC_States_LLCIncorporationV2": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormLLCInc {...tabProps} />,
        };
        break;
      }
      case "INC_BusinessLicenses":
      case "INC_States_BusinessLicenses": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormBusinessLicenses initOrderDetails={initOrderDetails} {...tabProps} />,
        };
        break;
      }
      case "INC_Rush_Processing":
      case "INC_States_Rush_Processing": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormIncRushProcessing {...tabProps} />,
        };
        break;
      }

      case "EIN_SoleProp": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormSoleProp {...tabProps} />,
        };
        break;
      }

      case "INC_EIN":
      case "INC_States_EIN":
      case "EIN_LLC": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormLLC {...tabProps} />,
        };
        break;
      }

      case "EIN_Trust": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormTrust {...tabProps} />,
        };
        break;
      }

      case "EIN_Estate": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormEstate {...tabProps} />,
        };
        break;
      }

      case "EIN_NonProfit": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormProfit {...tabProps} />,
        };
        break;
      }

      case "EIN_Church": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormChurch {...tabProps} />,
        };
        break;
      }

      case "EIN_Partnership": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormPartnership {...tabProps} />,
        };
        break;
      }

      case "EIN_PSC": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormPSC {...tabProps} />,
        };
        break;
      }

      case "EIN_CCorp": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormCCorp {...tabProps} />,
        };
        break;
      }

      case "EIN_SCorp": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormSCorp {...tabProps} />,
        };
        break;
      }

      case "INC_RegisterTrademark":
      case "INC_States_RegisterTrademark":
      case "TRADEMARK_RegisterTrademark":
      case "TRADEMARK_RegisterTrademarkV2":
      case "TRADEMARK_RegisterTrademark_Basic":
      case "TRADEMARK_RegisterTrademark_Standard":
      case "TRADEMARK_RegisterTrademark_Platinum": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: (
            <FormTrademarkRegister
              {...tabProps}
              availableProcessingStatus={availableProcessingStatus}
              initOrderDetails={initOrderDetails}
            />
          ),
        };
        break;
      }

      case "INC_Trademark_Search_Basic":
      case "INC_States_Trademark_Search_Basic":
      case "INC_Trademark_Search_Global":
      case "INC_Trademark_Search_Federal_State":
      case "INC_Trademark_Search_Common_Law":
      case "TRADEMARK_Trademark_Search_Basic":
      case "TRADEMARK_Trademark_Search_Global":
      case "TRADEMARK_Trademark_Search_Federal_State":
      case "TRADEMARK_Trademark_Search_Common_Law": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkSearch {...tabProps} />,
        };
        break;
      }

      case "INC_Trademark_Monitoring":
      case "INC_States_Trademark_Monitoring":
      case "TRADEMARK_Trademark_Monitoring": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkMonitoring {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Statement_Of_Use": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkStatementOfUse {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Office_Action_Response": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkOfficeActionResponse {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Renewal": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkRenewal {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Rush_Processing": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkRushProcessing {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Extension": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkExtension {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Revival_From_Abandonment": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkRevivalFromAbandonment {...tabProps} />,
        };
        break;
      }

      case "INC_USPTOFilingFee":
      case "INC_States_USPTOFilingFee":
      case "TRADEMARK_USPTOFilingFee": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkUSPTOFilingFee {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Keep_Trademark_Alive": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkKeepAlive {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Certified_Copy": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkCertifiedCopy {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Amendment_TrademarkMinor":
      case "TRADEMARK_Amendment": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormTrademarkAmendment {...tabProps} />,
        };
        break;
      }

      case "EIN_Amendment_RegisteredAgentChange":
      case "INC_Amendment_RegisteredAgentChange":
      case "INC_States_Amendment_RegisteredAgentChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyRaAmendment {...tabProps} />,
        };
        break;
      }

      case "EIN_Amendment_ManagerChange":
      case "INC_Amendment_ManagerChange":
      case "INC_States_Amendment_ManagerChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyManagerAmendment {...tabProps} />,
        };
        break;
      }

      case "EIN_Amendment_BusinessAddressChange":
      case "INC_Amendment_BusinessAddressChange":
      case "INC_States_Amendment_BusinessAddressChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyBusinessAddressAmendment {...tabProps} />,
        };
        break;
      }

      case "EIN_Amendment_AllOtherAmendments":
      case "INC_Amendment_AllOtherAmendments":
      case "INC_States_Amendment_AllOtherAmendments": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyOtherAmendment {...tabProps} />,
        };
        break;
      }

      case "EIN_Amendment_BusinessNameChange":
      case "INC_Amendment_BusinessNameChange":
      case "INC_States_Amendment_BusinessNameChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyBusinessNameAmendment {...tabProps} />,
        };
        break;
      }

      case "INC_Credit_Card_Processing":
      case "INC_States_Credit_Card_Processing": {
        tabInfo = {
          eventKey: "default_info",
          title: TAB_CODES.defaultInfo,
          c: <FormCreditCardProcessing {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Private_Contact_Information": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkPrivateContactInfo {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Privacy_Package": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkPrivacyPackage {...tabProps} />,
        };
        break;
      }

      case "TRADEMARK_Logo_Design_Service": {
        tabInfo = {
          eventKey: "logo_info",
          title: TAB_CODES.logoInfo,
          c: <FormTrademarkLogoDesignService {...tabProps} />,
        };
        break;
      }

      case "EIN_DBA":
      case "INC_DBA":
      case "INC_States_DBA": {
        tabInfo = {
          eventKey: "default_info",
          title: TAB_CODES.defaultInfo,
          c: <FormDBA {...tabProps} />,
        };
        break;
      }

      default:
        tabInfo = {
          eventKey: "default_info",
          title: TAB_CODES.defaultInfo,
          c: <FormDefaultEdit {...tabProps} />,
        };
        break;
    }

    return (
      tabInfo && (
        <Tab eventKey={tabInfo.eventKey} title={tabInfo.title}>
          {tabInfo.c}
        </Tab>
      )
    );
  };

  const productInfoRender = (product) => {
    return (
      <Fragment key={product.id}>
        <Form.Group key={product.id} as={Row}>
          <Form.Label column sm="12" onClick={() => onUncollapseProduct(product.id)} role="button">
            <i className={`fa fa-arrow-${uncollapsedProducts.includes(product.id) ? "up" : "down"}`} />
            &nbsp;
            {product.customerName}
          </Form.Label>
        </Form.Group>
        {uncollapsedProducts.includes(product.id) && (
          <>
            <hr />
            <Form.Group as={Row} className="pl-4">
              <Form.Label column sm="3">
                Processing Option:
              </Form.Label>
              <Form.Label column sm="9">
                {product?.processingOption}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row} className="pl-4">
              <Form.Label column sm="3">
                Price:
              </Form.Label>
              <Form.Label column sm="9">
                {currencyFormatter(product?.price)}
              </Form.Label>
            </Form.Group>

            {product?.recurringOrderPrice && (
              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Recurring Amount:
                </Form.Label>
                <Form.Label column sm="9">
                  {currencyFormatter(product?.recurringAmount || 0)}
                </Form.Label>
              </Form.Group>
            )}

            {product?.recurringOrderPrice && (
              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Recurring Refund:
                </Form.Label>
                <Form.Label column sm="9">
                  {currencyFormatter(product?.recurringRefund || 0)}
                </Form.Label>
              </Form.Group>
            )}

            <Form.Group as={Row} className="pl-4">
              <Form.Label column sm="3">
                Payment Status:
              </Form.Label>
              <Form.Label column sm="9">
                {orderStatusOpt[product?.paymentStatus] || "N/A"}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row} className="pl-4">
              <Form.Label column sm="3">
                Payment Date:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  type="date"
                  name={`paymentDate-${product.id}`}
                  max={moment().format("YYYY-MM-DD")}
                  value={datePickerFormatter(product.paymentDate)}
                  onChange={handleChangeOrderDate}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="pl-4">
              <Form.Label column sm="3">
                Refund Issues:
              </Form.Label>
              <Form.Label column sm="9">
                {product.refundAmount > 0 ? currencyFormatter(product?.refundAmount) : "No refund issues"}
              </Form.Label>
            </Form.Group>

            {product.refundAmount > 0 && (
              <>
                <Form.Group as={Row} className="pl-4">
                  <Form.Label column sm="3">
                    Refund Date:
                  </Form.Label>
                  <Form.Label column sm="9">
                    {!!product.refundDate ? moment.utc(product.refundDate).local().format("MM/DD/yyyy") : ""}
                  </Form.Label>
                </Form.Group>
              </>
            )}

            <Form.Group as={Row} className="pl-4">
              <Form.Label column sm="3">
                Merchant Descriptor:
              </Form.Label>
              <Form.Label column sm="9">
                {product.merchantDescriptor || "N/A"}
              </Form.Label>
            </Form.Group>
            <hr />
          </>
        )}
      </Fragment>
    );
  };

  return (
    <>
      <Container fluid className="py-5 orderinfo">
        <StyledForm noValidate validated={validated} onSubmit={handleUpdateOrder} ref={dataform}>
          <Row className="my-3">
            <Col md={4}>
              <div className="">
                <h4>Order Information:</h4>
                {orderDetails.order && (
                  <div>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        CRM Order ID:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control plaintext readOnly value={orderDetails.order.crmOrderId || ""} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Order Created:
                      </Form.Label>
                      <Form.Label column sm="9" title={orderDetails?.order?.created}>
                        {moment.utc(orderDetails?.order?.created).local().format("MM/DD/yyyy")}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        First Name:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control plaintext readOnly value={orderDetails.customer.firstName || ""} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Last Name:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control plaintext readOnly value={orderDetails.customer.lastName || ""} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        E-mail:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control plaintext readOnly value={orderDetails.customer.email || ""} />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Product Code:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.products.map((p, idx) => (
                          <p key={`code-${p.code.code}-${idx}`} title={p.code.code}>
                            {p.code.code}
                            <br />
                            <span style={{ color: "gray" }}>({processingStatusesOpt[p.processingResult.status]})</span>
                          </p>
                        ))}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Product Alternate Names:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {isLlcIncorporation(productDetails.code.code)
                          ? productDetails.altCompanyName
                          : productDetails.alternateLegalNames
                          ? productDetails.alternateLegalNames.join(",")
                          : ""}
                      </Form.Label>
                    </Form.Group>
                    {!isLlcIncorporation(productDetails.code.code) && (
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Entity Name:
                        </Form.Label>
                        <Form.Label column sm="9">
                          {customerName}
                        </Form.Label>
                      </Form.Group>
                    )}
                  </div>
                )}

                <div className="mt-2">
                  <Button className="btn btn-sm btn-primary mr-2" onClick={onViewChange}>
                    Cancel
                  </Button>
                  <Button className="btn btn-sm btn-default" type="submit">
                    <i className="fa fa-save" /> Save
                  </Button>
                </div>

                <div className="validation_messages mt-3">
                  {Object.keys(errors).map((prop) => (
                    <div key={prop}>
                      {errors[prop].map((field) => (
                        <Alert key={`${prop}-${field}`} variant="danger">
                          {field === "Wages" && (
                            <>
                              <strong>The date of payment of wages</strong> in <strong>{prop}</strong> Tab must be no
                              earlier than the start of the organization's work and no later than one year from today's
                              date
                            </>
                          )}
                          {field === "Members" && (
                            <>
                              Please, add at least 1 member in <strong>{prop}</strong> Tab
                            </>
                          )}
                          {field === "CreditCardProcessing" && (
                            <>
                              Please, add at least 1 Credit Card Processing type in <strong>{prop}</strong> Tab
                            </>
                          )}
                          {!["Wages", "Members", "CreditCardProcessing"].includes(field) && (
                            <>
                              Invalid value for <strong>{field}</strong> in <strong>{prop}</strong> Tab
                            </>
                          )}
                        </Alert>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col md={8}>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  <h4>Other Details:</h4>
                </Form.Label>
                <OrderProductSelect
                  selectedProduct={selectedProduct}
                  orderDetails={orderDetails}
                  onChange={onSelectedProduct}
                />
              </Form.Group>

              <Tabs defaultActiveKey="home" transition={false} activeKey={activeTab} onSelect={onTabChange}>
                <Tab eventKey="home" title="Order">
                  {orderDetails.order && (
                    <div className="order-info form-border">
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Order Id:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control plaintext readOnly value={orderDetails.order.id} />
                        </Col>
                      </Form.Group>

                      {orderDetails?.order?.parentOrderUid && (
                        <Form.Group as={Row}>
                          <Form.Label column sm="3">
                            Parent Order Id:
                          </Form.Label>
                          <Form.Label column sm="9">
                            <Link
                              to={"/admin/orders/" + orderDetails.order.parentOrderUid}
                              style={{ textDecoration: "underline" }}
                            >
                              {orderDetails.order.parentOrderUid}
                            </Link>
                          </Form.Label>
                        </Form.Group>
                      )}

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Order Created:
                        </Form.Label>
                        <Form.Label column sm="9" title={orderDetails?.order?.created}>
                          {moment.utc(orderDetails?.order?.created).local().format("MM/DD/yyyy")}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Order Updated:
                        </Form.Label>
                        <Form.Label column sm="9" title={orderDetails?.order?.updated}>
                          {moment.utc(orderDetails?.order?.updated).local().format("MM/DD/yyyy")}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Total Price:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            name="total"
                            type="text"
                            plaintext
                            readOnly
                            value={currencyFormatter(orderDetails.order.total)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Processing Option:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control plaintext readOnly value={orderDetails.order.processingOption} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          {productDetails.code.category === "EIN" ? "EIN Error:" : "Processing error:"}
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control plaintext readOnly value={productsErrors || "—"} />
                        </Col>
                      </Form.Group>

                      <br />
                      <h6 className="font-weight-bold">Account Manager Info:</h6>
                      <Form.Group as={Row} sm={12}>
                        <Form.Label column sm={5}>
                          New Account Manager:
                        </Form.Label>
                        <Col sm={7}>
                          <AsyncPaginate
                            required={
                              !!(
                                initOrderDetails?.order?.accountManagerId !== null &&
                                orderDetails?.order?.accountManagerId === null
                              )
                            }
                            className="async-select-container"
                            classNamePrefix="async-select"
                            placeholder="Search..."
                            isClearable={!!accountManager?.value}
                            additional={{ page: 1 }}
                            value={accountManager}
                            onChange={handleChangeAccountManager}
                            loadOptions={asyncOptionsList["loadAccountManagerOptions"]}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={5}>
                          Customer Contact required:
                        </Form.Label>
                        <Col sm={7}>
                          <Form.Control
                            as="select"
                            size="sm"
                            name="customerContactRequired"
                            value={orderDetails.order.customerContactRequired}
                            onChange={handleChangeCustomerContactRequired}
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <br />
                      <h6 className="font-weight-bold">Products Info:</h6>
                      {orderDetails.products.map(productInfoRender)}

                      <RelatedOrders />
                    </div>
                  )}
                </Tab>

                <Tab eventKey="customer" title="Customer">
                  {orderDetails.order && (
                    <div className="order-info form-border">
                      <br />
                      <h6 className="font-weight-bold">Customer INFO</h6>
                      <hr />

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Id:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control plaintext readOnly value={orderDetails.customer.id || ""} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          CRM Customer ID:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control plaintext readOnly value={orderDetails.customer.crmcustomerId || ""} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Konnektive Campaign ID:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control plaintext readOnly value={orderDetails.order.campaignId || ""} />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          First Name:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            required
                            name="firstName"
                            onChange={handleChangeCustomer}
                            value={orderDetails.customer.firstName || ""}
                            onInvalid={() => addError(TAB_CODES.customer, "First Name")}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Last Name:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            required
                            name="lastName"
                            onChange={handleChangeCustomer}
                            value={orderDetails.customer.lastName || ""}
                            onInvalid={() => addError(TAB_CODES.customer, "Last Name")}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Username:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            required
                            name="username"
                            onChange={handleChangeCustomer}
                            value={orderDetails.customer.username || ""}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          E-mail:
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            required
                            name="email"
                            // pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                            pattern="^[a-zA-Z0-9_.%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
                            onChange={handleChangeCustomer}
                            value={orderDetails.customer.email || ""}
                            onInvalid={() => addError(TAB_CODES.customer, "Email")}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Phone:
                        </Form.Label>
                        <Col sm="9">
                          <InputMask
                            required
                            className={clsx("form-control", "form-control-sm", {
                              "is-invalid":
                                !orderDetails.customer.phone ||
                                (orderDetails.customer.phone && !phoneNumRegexp.test(orderDetails.customer.phone)),
                            })}
                            mask="(999) 999-9999"
                            maskChar=" "
                            name="phone"
                            placeholder="(999) 999-9999"
                            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                            value={orderDetails.customer.phone || ""}
                            onChange={handleChangeCustomer}
                            onInvalid={() => addError(TAB_CODES.customer, "Phone")}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Gclid:
                        </Form.Label>
                        <Form.Label column sm="9" style={{ wordBreak: "break-all" }}>
                          {orderDetails.order.clid}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Source:
                        </Form.Label>
                        <Form.Label column sm="9">
                          {orderDetails.order.source}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Keyword:
                        </Form.Label>
                        <Form.Label column sm="9">
                          {orderDetails.order.keyword}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          AdAccount:
                        </Form.Label>
                        <Form.Label column sm="9">
                          {orderDetails.order.adAccount}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          First Click Url:
                        </Form.Label>
                        <Form.Label column sm="9" style={{ wordBreak: "break-all" }}>
                          {orderDetails.customer.firstCLickURL}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="12">
                          Billing Address:
                        </Form.Label>
                        <Col sm="12">
                          {orderDetails.customer.billingAddress && (
                            <div className="pl-3">
                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Address1:
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    required
                                    name="address1"
                                    pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                                    onChange={handleChangeBillingAddress}
                                    value={orderDetails.customer.billingAddress.address1 || ""}
                                    onInvalid={() => addError(TAB_CODES.customer, "Address1")}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Address2:
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    name="address2"
                                    pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                                    onChange={handleChangeBillingAddress}
                                    value={orderDetails.customer.billingAddress.address2 || ""}
                                    onInvalid={() => addError(TAB_CODES.customer, "Address2")}
                                  />
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  City:
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    required
                                    name="city"
                                    pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                                    onChange={handleChangeBillingAddress}
                                    value={orderDetails.customer.billingAddress.city || ""}
                                    onInvalid={() => addError(TAB_CODES.customer, "City")}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  County:
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    name="county"
                                    pattern="[ a-zA-Z\-&]*"
                                    // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                                    onChange={handleChangeBillingAddress}
                                    value={orderDetails.customer.billingAddress.county || ""}
                                    onInvalid={() => addError(TAB_CODES.customer, "County")}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  State:
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    required
                                    as="select"
                                    name="state"
                                    onChange={handleChangeBillingAddress}
                                    value={orderDetails.customer.billingAddress.state || ""}
                                    onInvalid={() => addError(TAB_CODES.customer, "State")}
                                  >
                                    <option value="">Select a state</option>
                                    {Object.keys(state_array).map((key) => (
                                      <option key={key} value={key}>
                                        {state_array[key]}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Zip Code:
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    required
                                    name="zip"
                                    pattern="^\d{5}$"
                                    onChange={handleChangeBillingAddress}
                                    value={orderDetails.customer.billingAddress.zip || ""}
                                    onInvalid={() => addError(TAB_CODES.customer, "Zip")}
                                  />
                                </Col>
                              </Form.Group>
                            </div>
                          )}
                        </Col>
                      </Form.Group>

                      {(isTrademark || isLlcIncorporation) && (
                        <>
                          <br />
                          <h6 className="font-weight-bold">Temporary password</h6>
                          <hr />
                          <PasswordReset orderDetails={orderDetails} />
                        </>
                      )}

                      <br />
                      <h6 className="font-weight-bold">Actions</h6>
                      <hr />
                      <CustomerbActions orderDetails={orderDetails} />
                    </div>
                  )}
                </Tab>
                {infoTabRenderer()}

                {orderDetails.order.productCodes.some((p) => checkIsStateIncorporationFee(p.code)) && (
                  <Tab eventKey="llc_fee" title="State Inc Fee">
                    <StateIncFee orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                {isLlcIncorporation(productDetails.code.code) && (
                  <Tab eventKey="llc_mail" title="Mail LLC">
                    <MailUpload
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      fileType="TRADEMARK_MAIL"
                    />
                  </Tab>
                )}

                {isLlcIncorporation(productDetails.code.code) && (
                  <Tab eventKey="llc_amendments" title="Amendments LLC">
                    <AmendmentsLLC orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                {isPrivacyProduct(productDetails.code.code) && (
                  <Tab eventKey="mail_upload" title="Mail Upload">
                    <MailUpload
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      isDirty={isDirty}
                      onShowConfirmation={handleShowConfirmation}
                    />
                  </Tab>
                )}

                {isTrademarkRegistration(productDetails.code.code) && (
                  <Tab eventKey="trademark_summary" title="Trademark Summary">
                    <FormTrademarkSummary orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                {isTrademarkRegistration(productDetails.code.code) && (
                  <Tab eventKey="trademark_timeline" title="Timeline">
                    <Timeline
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      availableProcessingStatus={availableProcessingStatus}
                      processingStatuses={
                        productsProcessingStatuses[
                          isTrademarkRegistration(productDetails?.code?.code)
                            ? productDetails?.code?.code
                            : "TRADEMARK_RegisterTrademark"
                        ]
                      }
                    />
                  </Tab>
                )}

                {!isLegalTeamAssesment(productDetails.code.code) && (
                  <Tab eventKey="filingFees" title="Filing Fees">
                    <FilingFees orderDetails={orderDetails} productDetails={productDetails} />
                  </Tab>
                )}

                {isLegalTeamAssesment(productDetails.code.code) && (
                  <Tab eventKey="assessment" title="Assessment">
                    <FormTrademarkAssessment
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      onChange={onChange}
                      onError={addError}
                    />
                  </Tab>
                )}

                <Tab eventKey="paymentRefunds" title="Payments">
                  <PaymentRefunds orderDetails={orderDetails} />
                </Tab>

                <Tab eventKey="emails" title="Emails">
                  <Emails orderDetails={orderDetails} selectedProduct={selectedProduct} />
                </Tab>

                {isTrademarkMonitoring(productDetails?.code?.code) && (
                  <Tab
                    eventKey="monitoring"
                    title={
                      <span>
                        Monitoring
                        <span className={statusIndicatorClasses(monitoringStatus)} />
                      </span>
                    }
                  >
                    <Monitoring orderDetails={orderDetails} productIdx={trademarkMonitoringProductIdx} />
                  </Tab>
                )}

                {isLogoDesingService(productDetails.code.code) && (
                  <Tab eventKey="revisions" title="Revisions">
                    <LogoDesignRevisions orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                {!isTrademarkMonitoring(productDetails?.code?.code) && productDetails?.code?.subscription && (
                  <Tab
                    eventKey="billing"
                    title={
                      <span>
                        Billing
                        <span className={statusIndicatorClasses(subscriptionStatus)} />
                      </span>
                    }
                  >
                    <Billing orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                <Tab eventKey="actions" title="Actions">
                  {orderDetails.order && (
                    <OrderActions
                      isEditable
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      isDirty={isDirty}
                      onChange={onChange}
                      onValidate={handleValidateActions}
                      onShowConfirmation={handleShowConfirmation}
                      onDeleteOrder={onDeleteOrder}
                    />
                  )}
                </Tab>
                <Tab eventKey="notes" title="Notes">
                  <Notes orderDetails={orderDetails} />
                </Tab>
                <Tab eventKey="zendesk" title="Tickets">
                  <Zendesk orderDetails={orderDetails} selectedProduct={selectedProduct} activeTab={activeTab} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </StyledForm>
      </Container>
      <ConfirmationModal
        show={isConfirmationOpen}
        handleClose={() => {
          setConfirmationOpen(false);
          onChange({
            ...orderDetails,
            order: { ...orderDetails.order, notes: [...initOrderDetails.order.notes] },
            products: orderDetails.products.map((product, idx) => {
              if (idx !== selectedProduct) return product;
              const newData = { ...initOrderDetails?.products[selectedProduct]?.processingResult };

              return { ...product, processingResult: { ...product.processingResult, ...newData } };
            }),
          });
          uploadPDFRef.current = null;
        }}
        handleConfirm={() => {
          setConfirmationOpen(false);
          handleUpdateOrder();
          uploadPDFRef.current = null;
        }}
        body="You have unsaved changes. Save the changes and continue?"
      />
    </>
  );
}

export default EditOrderDetail;

import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";

import { adsAccounts } from "_actions";

import AdAccountModal from "./AdAccountModal";

import Spinner from "components/Spinner";

const accountDataColumns = [
  { key: "type", label: "Type", style: { minWidth: "90px", maxWidth: "90px" } },
  { key: "category", label: "Category", style: { minWidth: "120px", maxWidth: "120px" } },
  {
    key: "managerAccount",
    label: "Manager Account",
    style: { minWidth: "100px", maxWidth: "100px" },
  },
  {
    key: "clientAccount",
    label: "Client Account",
    style: { minWidth: "100px", maxWidth: "100px" },
  },
  { key: "accountId", label: "Account ID", style: { minWidth: "100px", maxWidth: "100px" } },
  { key: "clientId", label: "Client ID" },
  { key: "clientSecret", label: "Client Secret" },
  { key: "refreshToken", label: "RefreshToken" },
  {
    key: "status",
    label: "Status",
    style: { minWidth: "100px", maxWidth: "100px" },
    render: (status) => {
      let color = "#000000";
      switch (status) {
        case "Active":
          color = "#008000";
          break;
        case "Disabled":
          color = "#FF0000";
          break;
        default:
          color = "#000000";
          break;
      }

      return <span style={{ color, fontWeight: "bold" }}>{status || "—"}</span>;
    },
  },
];

export default function AdAccount() {
  const dispatch = useDispatch();
  const adAccounts = useSelector(({ adsAccounts: { accounts } }) => accounts || []);
  const loading = useSelector(({ adsAccounts: { loading } }) => !!loading);

  const [modalStatus, setModalStatus] = useState("");
  const [selected, setSelected] = useState({});
  const [filterStatus, setFilterStatus] = useState("Active");

  const addAccount = () => {
    setModalStatus("Add");
    setSelected({});
  };

  const editAccount = (account) => {
    setModalStatus("Edit");
    setSelected(account);
  };

  const deleteAccount = (account) => {
    if (window.confirm(`Are you sure to delete account ${account.clientAccount || account.accountId || account.id}?`))
      dispatch(adsAccounts.deleteAdsAccount(account.id));
  };

  const handlePauseAccount = (account) => {
    if (window.confirm(`Are you sure to pause account ${account.clientAccount || account.accountId || account.id}?`))
      dispatch(adsAccounts.pauseAdsAccount(account.id));
  };

  const handleChangeFilterStatus = ({ target: { value } }) => {
    if (!loading) {
      setFilterStatus(value);
      dispatch(adsAccounts.getAdsAccounts({ status: value }));
    }
  };

  useEffect(() => {
    dispatch(adsAccounts.getAdsAccounts({ status: filterStatus }));
    dispatch(adsAccounts.getCategories());
  }, []);

  const headerRenderer = () => (
    <thead>
      <tr>
        {accountDataColumns.map(({ key, label, render, ...props }) => (
          <th key={key} {...props}>
            {label}
          </th>
        ))}
        <th style={{ minWidth: "80px", maxWidth: "80px" }}>Edit</th>
      </tr>
    </thead>
  );
  const accountRowRenderer = (account, key) => (
    <tr key={key}>
      {accountDataColumns.map(({ key: colKey, render, ...props }) => (
        <td key={key + colKey} {...props}>
          {render ? render(account[colKey]) : account[colKey] || "—"}
        </td>
      ))}

      <td style={{ minWidth: "80px", maxWidth: "80px" }}>
        <Button
          className="mb-1 ml-1"
          size="sm"
          variant="warning"
          style={{ width: "60px" }}
          onClick={() => editAccount(account)}
        >
          Edit
        </Button>

        <Button className="mb-1 ml-1" size="sm" style={{ width: "60px" }} onClick={() => handlePauseAccount(account)}>
          Pause
        </Button>

        <Button
          className="mb-1 ml-1"
          size="sm"
          variant="danger"
          style={{ width: "60px" }}
          onClick={() => deleteAccount(account)}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
  const tableBodyRenderer = () => <tbody>{adAccounts.map(accountRowRenderer)}</tbody>;

  return (
    <Container fluid className="mt-5 py-4 ads-accounts-table">
      <Row>
        <Col>
          <Row className="px-3">
            <Col className="d-flex align-items-end" sm={12} md={8}>
              <Form.Group className="d-flex justify-content-end align-items-center">
                <Form.Label className="my-0 mr-2">Status:</Form.Label>
                <Form.Control
                  required
                  as="select"
                  size="sm"
                  type="text"
                  name="status"
                  disabled={loading}
                  value={filterStatus || ""}
                  onChange={handleChangeFilterStatus}
                >
                  {/* <option value="">All</option> */}
                  {["Active", "Disabled"].map((status) => {
                    return (
                      <option key={`filter-status-${status}`} value={status}>
                        {status}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-start justify-content-end" sm={12} md={4}>
              <Button className="pull-right" size="sm" disabled={loading} onClick={() => !loading && addAccount()}>
                Add New
              </Button>
            </Col>
          </Row>
          {loading ? (
            <Spinner />
          ) : (
            <Table responsive hover bordered striped size="sm">
              {headerRenderer()}
              {tableBodyRenderer()}
            </Table>
          )}
        </Col>
      </Row>

      <AdAccountModal
        show={!!modalStatus}
        modalstatus={modalStatus}
        selected={selected}
        onHide={() => setModalStatus("")}
      />
    </Container>
  );
}

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Button } from "react-bootstrap";

import { adsAccounts } from "_actions";

const initAccount = {
  type: "",
  category: "",
};

const adsTypes = {
  GOOGLE: {
    type: "GOOGLE",
    category: "",
    clientId: "",
    clientSecret: "",
    managerAccount: "",
    clientAccount: "",
    refreshToken: "",
    status: "Active",
  },
  BING: {
    type: "BING",
    category: "",
    clientId: "",
    clientSecret: "",
    accountId: "",
    refreshToken: "",
    status: "Active",
  },
  FACEBOOK: {
    type: "FACEBOOK",
    category: "",
    clientId: "",
    clientSecret: "",
    accountId: "",
    refreshToken: "",
    status: "Active",
  },
};

export default function AdAccountModal(props) {
  const { modalstatus, selected } = props;
  const dispatch = useDispatch();

  const [account, setAccount] = useState(initAccount);

  const categories = useSelector(({ adsAccounts: { categories } }) => categories || []);

  const handleChange = ({ target: { name, value } }) => setAccount({ ...account, [name]: value });

  const handleChangeType = ({ target: { value } }) => {
    if (value) {
      if (selected && selected.type === value) {
        setAccount(selected);
      } else {
        setAccount(adsTypes[value]);
      }
    } else {
      setAccount(initAccount);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (modalstatus === "Edit") dispatch(adsAccounts.updateAdsAccounts(account));
    else dispatch(adsAccounts.addAdsAccounts(account));
    props.onHide();
  };

  const resetForm = () => {
    if (modalstatus === "Edit") setAccount(selected);
    else setAccount(initAccount);
  };

  const renderField = (field, modalstatus) => {
    switch (field) {
      case "managerAccount":
        return (
          <Form.Group key={field}>
            <Form.Label>Manager Account (Digits only)</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="managerAccount"
              pattern="^[0-9]*$"
              value={account.managerAccount || ""}
              onChange={handleChange}
            />
          </Form.Group>
        );

      case "clientAccount":
        return (
          <Form.Group key={field}>
            <Form.Label>Client Account (Digits only)</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="clientAccount"
              pattern="^[0-9]*$"
              value={account.clientAccount || ""}
              onChange={handleChange}
            />
          </Form.Group>
        );
      case "clientId":
        return (
          <Form.Group key={field}>
            <Form.Label>Client ID</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="clientId"
              value={account.clientId || ""}
              onChange={handleChange}
            />
          </Form.Group>
        );
      case "accountId":
        return (
          <Form.Group key={field}>
            <Form.Label>Account ID</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="accountId"
              value={account.accountId || ""}
              onChange={handleChange}
            />
          </Form.Group>
        );
      case "clientSecret":
        return (
          <Form.Group key={field}>
            <Form.Label>Client Secret</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="clientSecret"
              value={account.clientSecret || ""}
              onChange={handleChange}
            />
          </Form.Group>
        );
      case "refreshToken":
        return (
          <Form.Group key={field}>
            <Form.Label>RefreshToken</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="refreshToken"
              value={account.refreshToken || ""}
              onChange={handleChange}
            />
          </Form.Group>
        );
      case "status":
        if (modalstatus === "Add") return null;
        return (
          <Form.Group key={field}>
            <Form.Label>Status</Form.Label>
            <Form.Control
              required
              as="select"
              size="sm"
              type="text"
              name="status"
              value={account.status || "Active"}
              onChange={handleChange}
            >
              {["Active", "Disabled"].map((status) => {
                return (
                  <option key={`status-${status}`} value={status}>
                    {status}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    resetForm();
  }, [modalstatus, selected]);

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalstatus === "Add" ? "Add New Account" : "Edit Account"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          {modalstatus !== "Edit" && (
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control required as="select" size="sm" name="type" value={account.type} onChange={handleChangeType}>
                <option value="">Select Type...</option>
                {Object.keys(adsTypes).map((key) => (
                  <option key={`type-${key}`} value={key}>
                    {key}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              name="category"
              value={account.category || ""}
              style={{ background: 0 }}
              onChange={handleChange}
            >
              <option value="">Select Category...</option>
              {categories.map(({ code }) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {account.type && Object.keys(adsTypes[account.type]).map((key) => renderField(key, modalstatus))}
          <hr className="mt-5" />
          <Button className="mr-2" size="sm" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" size="sm">
            {modalstatus === "Add" ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

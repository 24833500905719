import { adminService } from "_services";
import { adsAccountsConstants } from "_constants";
import { alertActions } from "./";

const processAccountField = (account) => (account.category ? account : { ...account, category: null });

export const adsAccounts = {
  getAdsAccounts,
  addAdsAccounts,
  updateAdsAccounts,
  deleteAdsAccount,
  pauseAdsAccount,
  getCategories,
};

function getAdsAccounts(filters) {
  return (dispatch) => {
    dispatch(request());

    adminService.getAdsAccounts(filters).then(
      (accounts) => dispatch(success(accounts)),
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: adsAccountsConstants.GET_ADS_ACCOUNTS_REQUEST };
  }

  function success(payload) {
    return { type: adsAccountsConstants.GET_ADS_ACCOUNTS_SUCCESS, payload };
  }

  function failure() {
    return { type: adsAccountsConstants.GET_ADS_ACCOUNTS_FAILED };
  }
}

function addAdsAccounts(account) {
  return (dispatch) => {
    dispatch(request());

    adminService.addAdsAccounts(processAccountField(account)).then(
      (account) => {
        dispatch(alertActions.success("New account has been created."));
        dispatch(success(account));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: adsAccountsConstants.ADD_ADS_ACCOUNT_REQUEST };
  }

  function success(payload) {
    return { type: adsAccountsConstants.ADD_ADS_ACCOUNT_SUCCESS, payload };
  }

  function failure() {
    return { type: adsAccountsConstants.ADD_ADS_ACCOUNT_FAILED };
  }
}

function updateAdsAccounts(account) {
  return (dispatch) => {
    dispatch(request());

    adminService.updateAdsAccounts(processAccountField(account)).then(
      (account) => {
        dispatch(alertActions.success("Account has been updated."));
        dispatch(success(account));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: adsAccountsConstants.UPDATE_ADS_ACCOUNT_REQUEST };
  }

  function success(payload) {
    return { type: adsAccountsConstants.UPDATE_ADS_ACCOUNT_SUCCESS, payload };
  }

  function failure() {
    return { type: adsAccountsConstants.UPDATE_ADS_ACCOUNT_FAILED };
  }
}

function deleteAdsAccount(id) {
  return (dispatch) => {
    dispatch(request());

    adminService.deleteAdsAccount(id).then(
      () => {
        dispatch(alertActions.success("Account has been removed."));
        dispatch(success(id));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: adsAccountsConstants.DELETE_ADS_ACCOUNT_REQUEST };
  }

  function success(payload) {
    return { type: adsAccountsConstants.DELETE_ADS_ACCOUNT_SUCCESS, payload };
  }

  function failure() {
    return { type: adsAccountsConstants.DELETE_ADS_ACCOUNT_FAILED };
  }
}

function pauseAdsAccount(accountId) {
  return (dispatch) => {
    dispatch(request());

    adminService.pauseAdsAccount(accountId).then(
      () => {
        dispatch(alertActions.success("Account has been paused."));
        dispatch(success(accountId));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: adsAccountsConstants.PAUSE_ADS_ACCOUNT_REQUEST };
  }

  function success(payload) {
    return { type: adsAccountsConstants.PAUSE_ADS_ACCOUNT_SUCCESS, payload };
  }

  function failure() {
    return { type: adsAccountsConstants.PAUSE_ADS_ACCOUNT_FAILED };
  }
}

function getCategories() {
  return (dispatch) => {
    dispatch(request());

    adminService.getCategories().then(
      ({ values }) => dispatch(success(values)),
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: adsAccountsConstants.GET_CATEGORIES_REQUEST };
  }

  function success(payload) {
    return { type: adsAccountsConstants.GET_CATEGORIES_SUCCESS, payload };
  }

  function failure() {
    return { type: adsAccountsConstants.GET_CATEGORIES_FAILED };
  }
}
